<template>
  <div v-if="infos" class="interview">
    <div class="form">
      <div class="group">
        <div class="title">面谈时间</div>
        <div class="cont">
          <div class="tt">{{ infos.orderTime | parseTime }}</div>
        </div>
      </div>
      <div class="group">
        <div class="title">面谈地址</div>
        <div class="cont">
          <div class="tt">{{ infos.address }}</div>
          <div v-if="infos.addressImgUrl" class="map" :style="`background-image:url(${infos.addressImgUrl})`" @click="goMap" />
        </div>
      </div>
      <div class="group">
        <div class="title">备注信息</div>
        <div class="cont">
          {{ infos.remark }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { parseTime } from '@/utils/index'
import { getOssUrl } from '@/utils/oss'
// import { navToMap } from '@/utils/map'
import { openLocation } from '@/utils/wx.config'
export default {
  filters: {
    parseTime(time) {
      if (!time) return ''
      return parseTime(time, '{y}/{m}/{d} {h}:{i}:{s}')
    }
  },
  data() {
    return {
      infos: null
    }
  },
  async mounted() {
    this.infos = this.$route.params.infos
    console.log(this.infos, '111')
    if (!this.infos) {
      this.$router.go(-1)
    } else {
      if (this.infos.addressImgUrl) {
        this.infos.addressImgUrl = await getOssUrl(this.infos.addressImgUrl)
      }
    }
  },
  methods: {
    async goMap() {
      await this.$store.dispatch('wx/initWxConfig', window.location.href.split('#')[0])
      openLocation(this.infos)
    }
  }
}
</script>
  <style lang="scss" scoped>
  .interview{
    padding: 16px;
    .form {
      .group {
        display: flex;
        padding-bottom: 26px;
        .title {
          width: 80px;
          font-size: 14px;
          line-height: 20px;
          color: #999999;
        }
        .cont {
          flex: 1;
          line-height: 20px;
          .map {
            width: 100%;
            height: 200px;
            margin-top: 5px;
            background-size: 100%;
            background-position: center;
          }
        }
      }
    }
  }
  </style>

